<template>
    <div class="wrapper-profile">
        <div class="v-form" v-if="isLoading">Загрузка...</div>
        <!-- <p class="v-form" v-if="!isLoading && notNotFieldIn">Нет данных</p> -->

        <div v-if="notNotFieldIn">
            <WinnerView :rafflesId="rafflesId" />
        </div>

        <!-- <div v-if="documentStatus === 'pending'">
            <ContractView :rafflesId="rafflesId" :prizeName="prizeName" />
        </div> -->

        <div v-if="documentStatus === 'completed'">
            <ThankYouView />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import jwtDecode from "jwt-decode";
import { mapActions } from "vuex";

import WinnerView from "@/views/LC/WinnerView.vue";
// import ContractView from "@/views/LC/ContractView.vue";
import ThankYouView from "@/views/LC/ThankYouView.vue";

export default {
    components: { WinnerView, ThankYouView },
    data() {
        return {
            isLoading: false,
            rafflesId: "",
            userId: "",
            notNotFieldIn: false,
            prizeName: "",
            documentStatus: "",
        };
    },
    methods: {
        ...mapActions({
            getCurrentUserIdFromToken: "getCurrentUserIdFromToken",
            getUserPrize: "getUserPrize",
        }),
        async getPrizeData() {
            try {
                if (this.$route.params.id === "token") {
                    const dataFromToken = jwtDecode(...Object.values(this.$route.query));

                    this.userId = dataFromToken.id;
                    this.rafflesId = String(dataFromToken.rafflesId);
                } else {
                    const checkId = this.$store.state.prizeList.some(
                        ({ id }) => id === this.$route.params.id
                    );

                    if (checkId) {
                        this.userId = this.$store.state.userIdFromToken;
                        this.rafflesId = String(this.$route.params.id);
                    } else this.$router.push({ name: "prize" }).catch(() => {});
                }

                this.isLoading = true;

                const { data } = await axios.post(`${process.env.VUE_APP_NT}/raffle/get-prize`, {
                    userId: this.userId,
                    rafflesId: this.rafflesId,
                });

                this.prizeName = data.data.prize;
                this.documentStatus = data.data.document_status;
            } catch (error) {
                this.notNotFieldIn = true;
            } finally {
                this.isLoading = false;
            }
        },
    },

    mounted() {
        this.getCurrentUserIdFromToken();
        this.getPrizeData();
        this.getUserPrize();
    },
};
</script>

<style scoped>
.table-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.table-body {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    background-color: #f6f6f6;
    border-radius: 10px;
}

.table-line {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
}

.field-title {
    display: flex;
    align-items: center;
    background-color: #dce4ec;
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
}

.field-title-sended {
    display: flex;
    align-items: center;
    background-color: green;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
}

.field-value {
    overflow-x: auto;
    flex: 1;
    text-align: left;
}

.v-btn:not(.v-btn--round).v-size--default {
    margin-top: 0px;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
    .wrapper-profile {
        max-width: 800px;
        width: 100%;
    }

    .v-form {
        border-radius: 16px;
        padding: 60px;
    }

    .table-line {
        gap: 15px;
        flex-direction: row;
        align-items: center;
    }
}
</style>
